<template>
    <div class="lokerView">
       <div class="flex w-full justify-between lg:flex-row flex-col">
           <div class="text-left lg:w-3/5 w-full"> 
               <h5 class="text-base">{{ $t('karir.our') }}</h5>
               <h1 class="text-lg md:text-xl font-bold">
                   <span v-if="lang == 'id'">{{LokerView.title}}</span>
                   <span v-else>{{LokerView.title_eng}}</span>
                </h1>

               <div class="mt-10" v-if="lang == 'id'">
                   <h1 class="font-bold text-base md:text-xl mb-2">Tanggung Jawab Pekerjaan</h1>
                   <div v-html="LokerView.responsibilities" class="text-sm md:text-base dvRespo"></div>
               </div>

               <div class="mt-10" v-if="lang == 'gb'">
                   <h1 class="font-bold text-base md:text-xl mb-2">Job responsibilities</h1>
                   <div v-html="LokerView.responsibilities_eng" class="text-sm md:text-base dvRespo"></div>
               </div>

               <div class="mt-10 dvRequire" v-if="lang == 'id'">
                   <h1 class="font-bold text-base md:text-xl mb-2">Syarat Pekerjaan</h1>
                   <div v-html="LokerView.requirement"></div>
               </div>

               <div class="mt-10 dvRequire" v-if="lang == 'gb'">
                   <h1 class="font-bold text-base md:text-xl mb-2">Job requirements</h1>
                   <div v-html="LokerView.requirement_eng"></div>
               </div>
           </div>
           <div class="border border-grays-400 bg-grays-100 lg:w-2/6 w-full mt-10 lg:mt-0 p-10 text-left rounded-sm">
                <h1 class="font-bold text-xl mb-2">{{ $t('karir.ot') }}</h1>
                <div class="mb-4 text-sm md:text-base text-grays-500">
                    <h5 class="font-semibold">{{ $t('karir.loc') }}</h5>
                    <span>{{LokerView.location}}</span>
                </div>
                <div class="mb-4 text-sm md:text-base text-grays-500">
                    <h5 class="font-semibold">{{ $t('karir.years') }}</h5>
                    <span v-if="lang == 'id'">{{LokerView.experience}} Tahun</span>
                    <span v-else>{{LokerView.experience}} Years</span>
                </div>
                <div class="mb-4 text-sm md:text-base text-grays-500">
                    <h5 class="font-semibold">{{ $t('karir.status') }}</h5>
                    <span>{{LokerView.status}}</span>
                </div>
                <div class="mb-4 text-sm md:text-base text-grays-500">
                    <h5 class="font-semibold">{{ $t('karir.close') }}</h5>
                    <span>{{dateExperied}} </span>
                </div>
                <div class="mb-4 text-sm md:text-base text-grays-500">
                    <h5 class="font-semibold">{{ $t('karir.av') }}</h5>
                    <span>{{LokerView.available}}</span>
                </div>
                
                <a 
                    :href="LokerView.link"
                    class="cursor-pointer w-full bg-secondary text-grays-50 flex h-11 items-center justify-center rounded-sm mt-20"
                >{{ $t('karir.apply') }}</a>
           </div>
       </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import moment from 'moment'
export default {
    computed: {
        ...mapState({
            company: state => state.karir.company ? state.karir.company.data :'',
			LokerView: state => state.karir.viewLoker ? state.karir.viewLoker.data :'',
			lang: state => state.home.lang ? state.home.lang :'id',
        }),

        dateExperied(){
            if(this.lang == 'id'){
                return moment(this.LokerView.expired_at).locale("de").format('DD MMMM YYYY');
            }else{
                return moment(this.LokerView.expired_at).format('DD MMMM YYYY');
            }
        }
    },
    
    methods: {
        handApply(){
            this.$router.push(
                { 
                    name: "Apply"
                }
            )
        }
    }
}
</script>
<style lang="scss">
    .dvRespo{
        ul, ol{
            @apply text-sm lg:text-base pl-5;

            li{
                @apply mb-2;
            }
        }

         ol{
            @apply list-decimal;
        }

        ul{
            @apply list-disc
        }
    }
    .dvRequire{
        ul, ol {
            @apply text-sm md:text-base pl-5;
        }


         ol{
            @apply list-decimal;
        }

        ul{
            @apply list-disc
        }
    }
</style>